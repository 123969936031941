<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Tipo Habitación - Nuevo</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="Validate">
              <b-row>

                <b-col md="3">
                  <b-form-group>
                    <b-card-img :src="url_base + '/img/default.jpg'"></b-card-img>
                  </b-form-group>
                  <b-form-group>
                    <b-form-file @change="onFileChange" accept="image/*" v-model="photo" placeholder="Seleccione un foto..." drop-placeholder="Suelta la imagen aquí..."></b-form-file>
                    <small v-if="errors.photo" class="form-text text-danger">Seleccione un foto</small>
                  </b-form-group>
                </b-col>

                <b-col md="9">
                  <b-tabs content-class="mt-3">
                    <b-tab title="Datos del tipo de habitación" active>
                        <b-row>
                          

                          <b-col md="2">
                            <b-form-group label="Codigo:">
                              <b-form-input  type="text" v-model="room_type.code"></b-form-input>
                              <small v-if="errors.code" class="form-text text-danger" >Ingrese un código</small>
                            </b-form-group>
                          </b-col>

                          <b-col md="4">
                            <b-form-group label="Nombre:">
                              <b-form-input  type="text" v-model="room_type.name" ></b-form-input>
                              <small v-if="errors.name" class="form-text text-danger">Ingrese un nombre</small>
                            </b-form-group>
                          </b-col>

                          <b-col md="2">
                            <b-form-group label="Desayuno:">
                              <b-form-select v-model="room_type.breakfasts" :options="breakfasts"></b-form-select>
                            </b-form-group>
                          </b-col>

                          <b-col md="2">
                            <b-form-group label="Nro Huespedes:">
                              <b-form-input  type="number" v-model="room_type.number_guests" ></b-form-input>
                              <small v-if="errors.number_guests" class="form-text text-danger">Ingrese el nro de Huespedes</small>
                            </b-form-group>
                          </b-col>

                          <b-col md="2">
                            <b-form-group label="Estado:">
                              <b-form-select v-model="room_type.state" :options="state"></b-form-select>
                            </b-form-group>
                          </b-col>

                          <b-col md="12">
                            <b-form-group label="Descripción:">
                              <b-form-textarea ref="description"  v-model="room_type.description"></b-form-textarea>
                            </b-form-group>
                          </b-col>

                          <b-col md="10">
                          </b-col>

                          <b-col md="2">
                            <b-form-group >
                              <b-button type="button" @click="ModalBedsShow" class="form-control"  variant="primary" ><i class="fas fa-bed"></i> Camas</b-button >
                            </b-form-group>
                          </b-col>

                          <div  class="table-responsive">
                            <table class="table table-hover table-bordered">
                              <thead>
                                <tr>
                                  <th width="5%" class="text-center">#</th>
                                  <th width="65%" class="text-center">Nombre</th>
                                  <th width="15%" class="text-center">Cantidad</th>
                                  <th width="5%" class="text-center">Foto</th>
                                  <th width="10%" class="text-center">Acciones</th>
                                </tr>
                              </thead>
                              <tbody v-for="(item, it) in room_type.beds" :key="it">
                                <tr>
                                  <td class="text-center">{{ it + 1 }}</td>
                                  <td class="text-left"> {{ item.name }}</td>
                                  <td class="text-center"> {{ item.quantity }}</td>
                                  <td class="text-center"><b-card-img :src="url_base + item.photo"></b-card-img></td>
                                  <td class="text-center">
                                    <b-button type="button" @click="DeleteBed(it)" variant="danger" ><i class="fas fa-trash-alt"></i></b-button >
                                    
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>


                          
                          <b-col md="10">
                          </b-col>

                          <b-col md="2">
                            <b-form-group >
                              <b-button type="button" class="form-control" @click="ModalServicesShow"   variant="primary" ><i class="fas fa-save"></i> Servicios</b-button >
                            </b-form-group>
                          </b-col>

                          <div  class="table-responsive">
                            <table class="table table-hover table-bordered">
                              <thead>
                                <tr>
                                  <th width="5%" class="text-center">#</th>
                                  <th width="80%" class="text-center">Nombre</th>
                                  <th width="5%" class="text-center">Foto</th>
                                  <th width="10%" class="text-center">Acciones</th>
                                </tr>
                              </thead>
                              <tbody v-for="(item, it) in room_type.services" :key="it">
                                <tr>
                                  <td class="text-center">{{ it + 1 }}</td>
                                  <td class="text-left"> {{ item.name }}</td>
                                  <td class="text-center">
                                         <b-card-img :src="url_base + item.photo"></b-card-img>
                                  </td>
                                  <td class="text-center">
                                    <b-button type="button" @click="DeleteService(it)" variant="danger" ><i class="fas fa-trash-alt"></i></b-button >
                                    
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <b-col md="5"></b-col>
                          <b-col md="2">
                            <b-button type="submit" class="form-control"  variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button >
                          </b-col>

                        </b-row>
                    </b-tab>
                    <b-tab title="Mas Fotos">
                      <b-row>
                        
                          <b-col md="12">
                            <b-form-group label="Mas Imagenes:">
                              <b-form-file multiple accept="image/*" @change="previewMultiImage"  v-model="more_photos"></b-form-file>
                            </b-form-group>
                          </b-col>
       
                          <div class="col-md-12 border">
                            <div class="w-100 mb-2">Vista Previa:</div>
                            <div class="row w-100 m-1">
                              <template  v-if="preview_list.length">
                                <div class="border col-md-3 mb-1 text-center" v-for="item, index in preview_list" :key="index">
                                  <img :src="item" class="img-fluid text-center img-preview" />
                                </div>
                              </template>
                            </div>
                          </div>
        
                          
                          <b-col md="12">
                            <br><br>
                          </b-col>
                          <b-col md="5"></b-col>
                          <b-col md="2">
                            <b-button type="submit" class="form-control"  variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button >
                          </b-col>


                      </b-row>

                    </b-tab>
                  </b-tabs>

                  

                    
                </b-col>
                
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <ModalServices/>
    <ModalBeds/>
    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>
<style scoped>
.img-preview{
  max-height: 200px;
} 
</style>
<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import LoadingComponent from './../pages/Loading'
import ModalServices from './../components/ModalServices'
import ModalBeds from './../components/ModalBeds'
import EventBus from '@/assets/js/EventBus';
export default {
  name: "UsuarioAdd",
  components:{
      ModalServices,
      ModalBeds,
     vueDropzone: vue2Dropzone,
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  data() {
    return {
      
      isLoading: false,
      module: "RoomType",
      role: 2,
      room_type: {
        id_room_type: "",
        code: "",
        name: "",
        description: "",
        number_beds: "",
        breakfasts: 1,
        number_guests: 1,
        photo: "",
        beds: [],
        services: [],
        more_photos: [],
        state: 1,
      },
      photo: null,
      more_photos : null,
      breakfasts:[
        {value:0,text:'NO'},
        {value:1,text:'SI'},
      ],
      
      state:[
        {value:0,text:'Inactivo'},
        {value:1,text:'Activo'},
      ],
         preview_list: [],
      image_list: [],
    
      errors: {
        code: false,
        name: false,
        description: false,
        number_beds: false,
        breakfasts: false,
        number_guests: false,
        beds: false,
        services: false,
      },
      validate: false,
    };
  },
  mounted() {
    EventBus.$on('AddBeds', (bed) => {
      this.room_type.beds.push(bed);
    });
    EventBus.$on('AddServices', (service) => {
      console.log(service)
      this.room_type.services.push(service);
    });
  },
  methods: {
    previewMultiImage: function(event) {
      var input = event.target;
      var count = input.files.length;
      var index = 0;
      if (input.files) {
        while(count --) {
          this.preview_list = [];
          this.image_list = [];
          var reader = new FileReader();
          reader.onload = (e) => {
            this.preview_list.push(e.target.result);
          }
          this.image_list.push(input.files[index]);
          reader.readAsDataURL(input.files[index]);
          index ++;
        }
      }
    },
    DeleleMorePhoto,

    ClearPhotos,
    onFileChange,
    AddRoomType,
    Validate,
    handleImages,

    ModalBedsShow,
    ModalServicesShow,

    DeleteBed,
    DeleteService,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};

function DeleleMorePhoto(index) {
  this.more_photos.splice(index, 1);
  this.preview_list.splice(index, 1);
  this.image_list.splice(index, 1);
  
}

function ClearPhotos() {
    this.$refs.myVueDropzone.removeAllFiles();
}

function DeleteBed(index) {
    this.room_type.beds.splice(index, 1);
}

function DeleteService(index) {
    this.room_type.services.splice(index, 1);
}

function ModalBedsShow() {
  EventBus.$emit('ModalBedsShow',this.role);
}
function ModalServicesShow() {
  EventBus.$emit('ModalServicesShow',this.role);
}

function handleImages(files) {
  console.log(files)
  this.room_type.more_photos = files;
}

function onFileChange(e) {
  
  this.room_type.photo = e.target.files[0];
    console.log(this.room_type.photo)
}

function AddRoomType(me) {
  let url = me.url_base + "room-type/add";
  let data = new FormData();

  if (this.more_photos != null) {
    for( var i = 0; i < this.more_photos.length; i++ ){
      let file = this.more_photos[i];
      data.append('more_photos[' + i + ']', file);
    }
  }
  

  data.append("code", this.room_type.code);
  data.append("name", this.room_type.name);
  data.append("description", this.room_type.description);
  data.append("number_beds", this.room_type.number_beds);
  data.append("breakfasts", this.room_type.breakfasts);
  data.append("number_guests", this.room_type.number_guests);
  data.append("photo", this.room_type.photo);
  data.append("beds", JSON.stringify((this.room_type.beds)));
  data.append("services", JSON.stringify((this.room_type.services)));
  data.append("state", this.room_type.state);
  

  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: 2,},
  })
    .then(function (response) {
      if (response.data.status == 201) {
        me.room_type.code = "";
        me.room_type.name = "";
        me.room_type.description = "";
        me.room_type.number_beds = 0;
        me.room_type.breakfasts = 1;
        me.room_type.number_guests = 1;
        me.room_type.photo = "";
        me.room_type.beds = [];
        me.room_type.services = [];
        me.room_type.more_photos = [];
        me.room_type.state = 1;
        me.photo = null;
        me.more_photos = null;
        me.preview_list = [];
        me.image_list = [];
        Swal.fire({ icon: 'success', text: 'Se ha registrado el tipo de habitación', timer: 3000,})
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}

/**
 * 
 */
function Validate() {

  this.errors.code = this.room_type.code.length == 0 ? true : false;
  this.errors.name = this.room_type.name.length == 0 ? true : false;
  this.errors.description = this.room_type.description.length == 0 ? true : false;
  this.errors.breakfasts = this.room_type.breakfasts.length == 0 ? true : false;

  if (this.errors.code) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.name) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.description) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.breakfasts) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }

  let me = this;

  if (!this.validate) {
    Swal.fire({
      title: "Esta seguro de registrar el tipo de habitación ?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Estoy de acuerdo!",
    }).then((result) => {
      if (result.value) {
        this.AddRoomType(me);
      }
    });

  }

}
</script>
